<script setup>
import HowItWorksBlock from '~/components/howItWorks/HowItWorksBlock'
import PointingUpIcon from '~/assets/icons/pointing-up.svg'
import GiftBoxIcon from '~/assets/icons/gift-box.svg'
import OpenBookIcon from '~/assets/icons/book-flip-page.svg'
import { scrollIntoView } from '~/utils'

function scrollTo(selector) {
  const el = document.querySelector(`[scroll-id=${selector}]`)

  scrollIntoView(
    el,
    {
      behavior: 'smooth',
    },
    30,
  )
}
</script>

<template>
  <div class="banner pt-56 pb-32 pt-lg-96">
    <div class="container d-flex flex-column align-items-center">
      <div
        class="row d-flex justify-content-center align-items-center mb-48 mb-md-64"
      >
        <h2 class="mb-0 text-center">
          {{ $label('common.howitworks.home.title') }}
        </h2>
      </div>

      <div class="row">
        <HowItWorksBlock
          :title="$label('common.howitworks.home.firstBlock.title')"
          :text="$label('common.howitworks.home.firstBlock.bodyTxt')"
          :button-label="$label('common.howitworks.home.firstBlock.btnText')"
          :on-button-click="() => scrollTo('products')"
        >
          <div class="how-it-works__illustration"><PointingUpIcon /></div>
        </HowItWorksBlock>

        <HowItWorksBlock
          :title="$label('common.howitworks.home.secondBlock.title')"
          :text="$label('common.howitworks.home.secondBlock.bodyTxt')"
          :button-label="$label('common.howitworks.home.secondBlock.btnText')"
          :on-button-click="() => scrollTo('books-carousel')"
        >
          <div class="how-it-works__illustration"><GiftBoxIcon /></div>
        </HowItWorksBlock>

        <HowItWorksBlock
          :title="$label('common.howitworks.home.thirdBlock.title')"
          :text="$label('common.howitworks.home.thirdBlock.bodyTxt')"
          :button-label="$label('common.howitworks.home.thirdBlock.btnText')"
          :on-button-click="() => $router.push('boeken')"
        >
          <div class="how-it-works__illustration">
            <OpenBookIcon stroke="#007754" />
          </div>
        </HowItWorksBlock>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.banner {
  background-color: $grey-01;
}

.how-it-works__illustration {
  background-color: $white;
  padding: 6.2%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  align-self: flex-start;

  svg {
    height: 24px;
    width: 24px;
  }

  &:first-of-type {
    mask: url('~/assets/icons/ellipse40.svg') no-repeat 50% 50%;
    mask-size: 100%;
  }
  &:nth-of-type(2) {
    mask: url('~/assets/icons/ellipse41.svg') no-repeat 50% 50%;
    mask-size: 100%;
  }
  &:nth-of-type(2) {
    mask: url('~/assets/icons/ellipse42.svg') no-repeat 50% 50%;
    mask-size: 100%;
  }
}

@include media-breakpoint-down(md) {
  .how-it-works__block {
    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }
}

@include media-breakpoint-up(md) {
  .row {
    width: 100%;
  }
  .banner {
    padding-bottom: 128px;
  }
  .how-it-works__illustration {
    background-color: $white;
    padding: 11.1%;
    align-self: center;

    svg {
      height: 48px;
      width: 48px;
    }
  }
}

.col {
  flex: 1;
}
</style>
